import React, {createContext, FC, PropsWithChildren, useEffect, useState} from "react";
import {ApolloQueryResult, gql, useQuery} from "@apollo/client";
import {IAppContext, IBaseProfile, ISchedule, IUser} from "./IAppContext";
import Loading from "../containers/Loading";
import moment from 'moment-timezone';
import {DeviceTypes, useDeviceTypeGetter} from "../hooks/useDeviceType";

const AUTH_USER = gql`
    query GetAuthUser {
        currentAccountUser {
            firstName,
            lastName,
            picture,
            email,
            timezone,
            subscriptionType,
            googleDriveRootFolder,
	        deleteFilesEnabled,
            _id,
            admin,
            storage {
                serviceId,
                usedStorage,
                objectsCount
            }
            schedule {
                title
                serviceId
                formattedSettings
	            backupSettings
	            scheduleSettings
                active
            }
            services {
                serviceId
                status
            }
        }
        storageUseds {
            userId
            serviceId
            usedStorage
            objectsCount
        }
        currentAccount {
            registeredAt
            storageLimit
            usersLimit
            financialStatus
            email
            daysLeft
	        isPhotosConnected
            currentPlan {
                name
            }
        }
        notifications {
            message
            type
            title
        }
        tasks(first: 1) {
            edges{
                node{
                    serviceId
                }
            }
        }
        getProfile {
            loginEnabled,
            mfaEnabled
        }
    }
`;

const defaultContext: IAppContext = {
	addProcess: () => {},
	refreshInfo: () => new Promise<ApolloQueryResult<any>>(() => {}),
	updateSchedule: (schedule :ISchedule) => {},
	updateProfile: (p:IBaseProfile) => {},
	hasTasks: false,
	ui: {
		menuType: "collapsed",
		perPage: 50,
		toggleMenu: (): void => {},
		setPerPage: (): void => {},
		setUiState: (menu: boolean, header: boolean): void => {},
		setLoading: (loading:boolean) => {},
		noMenu: false,
		noHeader: false,
		isLoading: false,
		deviceType: DeviceTypes.XL,
	}
};

export const AppContext = createContext<IAppContext>(defaultContext);

export const AppProvider: FC<PropsWithChildren> = ({children}) => {
	const [menuType, setMenuType] = useState(defaultContext.ui.menuType);
	const [perPage, setPerPage]   = useState(defaultContext.ui.perPage);
	const [noMenu, setNoMenu]     = useState<boolean>(true);
	const [noHeader, setNoHeader] = useState<boolean>(true);

	const {loading, data, error, refetch} = useQuery(AUTH_USER, {
		onCompleted: (data: any) => {
			moment.tz.setDefault(data.currentAccountUser.timezone);
		}
	});
	const [headerLoading, setHeaderLoading] = useState<boolean>(false);
	const [authUser, setAuthUser] = useState<IUser>(data?.currentAccountUser);
	const [profile, setProfile] = useState<IBaseProfile>(data?.getProfile);
	const toggleMenu = () => setMenuType(menuType === "collapsed" ? "expanded" : "collapsed");
	const setUiState = (menu: boolean, header: boolean) => {
		setNoMenu(menu);
		setNoHeader(header);
	};
	const updateSchedule = (s: ISchedule) => {
		let user = {...authUser};
		user.schedule = user.schedule!.map(v => v.serviceId === s.serviceId ? {...v, ...s} : v);
		setAuthUser(user);
	};

	const updateProfile = (p: IBaseProfile) => {
		setProfile(p);
	};

	useEffect(() => {

		if(!loading && data){
			if(data.currentAccountUser) {
				setAuthUser(data.currentAccountUser);
			}
			if(data.getProfile){
				setProfile(data.getProfile);
			}
		}
	}, [loading, data]);

	const deviceType = useDeviceTypeGetter();

	const setLoading = (loading: boolean) => setHeaderLoading(loading);

	return (
		<AppContext.Provider value={{
			authUser: authUser,
			account: !loading && data && data.currentAccount,
			storageUsed: !loading && data && data.storageUseds,
			notifications: !loading && data && data.notifications,
			hasTasks: !loading && data && data.tasks.edges.length > 0,
			accountUsers: !loading && data && data.accountUsers,
			profile: profile,
			addProcess: () => {},
			updateSchedule: updateSchedule,
			updateProfile: updateProfile,
			refreshInfo: refetch,
			ui: {
				menuType,
				perPage,
				toggleMenu,
				setPerPage,
				setUiState,
				setLoading,
				noMenu,
				noHeader,
				isLoading: headerLoading,
				deviceType,
			}
		}}>
			{loading ? <Loading/> : error ? null : children}
		</AppContext.Provider>
	);
};

