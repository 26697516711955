import React, {FC, lazy, ReactElement, useContext, useEffect} from "react";

import {
	CalendarOutlined,
	ClockCircleOutlined,
	CreditCardOutlined,
	DashboardOutlined,
	IconCalendar,
	IconContacts,
	IconDrive,
	IconGmail,
	IconPhotos,
	IconTasks,
	SettingOutlined,
	TeamOutlined
} from "../icons/icons";

import {ServiceColors, Services} from "./Services";
import {Route, Routes} from "react-router-dom";
import {AppContext} from "../context/AppContext";
import {Result} from "../antd";

import PhotosConnectCallback from "../containers/connectCallback/PhotosConnectCallback";
import GoogleConnectCallback from "../containers/connectCallback/GoogleConnectCallback";

const GooglePhotos: FC   = lazy(() => import('../containers/services').then((module) => ({default: module.GooglePhotos})));
const GoogleMail: FC     = lazy(() => import('../containers/services').then((module) => ({default: module.GoogleMail})));
const GoogleDrive: FC    = lazy(() => import('../containers/services').then((module) => ({default: module.GoogleDrive})));
const GoogleTasks: FC    = lazy(() => import('../containers/services').then((module) => ({default: module.GoogleTasks})));
const GoogleContacts: FC = lazy(() => import('../containers/services').then((module) => ({default: module.GoogleContacts})));
const GoogleCalendar: FC = lazy(() => import('../containers/services').then((module) => ({default: module.GoogleCalendar})));

const Dashboard: FC  = lazy(() => import('../containers/tools').then((module) => ({default: module.Dashboard})));
const Activity: FC   = lazy(() => import('../containers/tools').then((module) => ({default: module.Activity})));
const Autobackup: FC = lazy(() => import('../containers/tools').then((module) => ({default: module.AutobackupSettings})));
const Users: FC      = lazy(() => import('../containers/tools').then((module) => ({default: module.Users})));
const Upgrade: FC    = lazy(() => import('../containers/tools').then((module) => ({default: module.Upgrade})));
const AccountSettings: FC = lazy(() => import('../containers/tools').then((module) => ({default: module.AccountSettings})));

const SearchContainer: FC = lazy(() => import('../containers/search/SearchContainer'));

export enum RouteName {
	GMail,
	GCalendar,
	GDrive,
	GPhotos,
	GContacts,
	GTasks,
	Dashboard,
	Users,
	Autobackup,
	Activity,
	Billing,
	Account,
	Search,
	Error404
}
export interface IService {
	icon: ReactElement;
	title: string;
	url: string | null;
	serviceId?: string;
	color?: string;
	isBaseRoute?: boolean;
	adminOnly: boolean;
	component?: ReactElement;
	code?: number;
	route: RouteName;
}

export const services: Array<IService> = [
	{
		icon: <IconGmail style={{fontSize: 18}}/>,
		title: 'Google Mail',
		url: '/app/gmail',
		color: ServiceColors[Services.SERVICE_GOOGLE_MAIL],
		serviceId: Services.SERVICE_GOOGLE_MAIL,
		adminOnly: false,
		component: <GoogleMail/>,
		route: RouteName.GMail
	},
	{
		icon: <IconCalendar style={{fontSize: 18}}/>,
		title: 'Google Calendar',
		url: '/app/gcalendar',
		color: ServiceColors[Services.SERVICE_GOOGLE_CALENDAR],
		serviceId: Services.SERVICE_GOOGLE_CALENDAR,
		adminOnly: false,
		component: <GoogleCalendar/>,
		route: RouteName.GCalendar
	},
	{
		icon: <IconDrive style={{fontSize: 18}}/>,
		title: 'Google Drive',
		url: '/app/gdrive',
		color: ServiceColors[Services.SERVICE_GOOGLE_DRIVE],
		serviceId: Services.SERVICE_GOOGLE_DRIVE,
		adminOnly: false,
		component: <GoogleDrive/>,
		route: RouteName.GDrive,
	},
	{
		icon: <IconPhotos style={{fontSize: 18}}/>,
		title: 'Google Photos',
		url: '/app/gphotos',
		color: ServiceColors[Services.SERVICE_GOOGLE_PHOTOS],
		serviceId: Services.SERVICE_GOOGLE_PHOTOS,
		adminOnly: false,
		component: <GooglePhotos/>,
		route: RouteName.GPhotos
	},
	// {
	// 	icon: <IconPicasa style={{fontSize: 18}}/>,
	// 	title: 'Google Picasa',
	// 	url: '/app/gpicasa',
	// 	color: ServiceColors[Services.SERVICE_GOOGLE_PICASA],
	// 	serviceId: Services.SERVICE_GOOGLE_PICASA,
	// 	adminOnly: false,
	// 	component: <GooglePhotos/>
	// },
	{
		icon: <IconContacts style={{fontSize: 18}}/>,
		title: 'Google Contacts',
		url: '/app/gcontacts',
		color: ServiceColors[Services.SERVICE_GOOGLE_PEOPLE],
		serviceId: Services.SERVICE_GOOGLE_PEOPLE,
		adminOnly: false,
		component: <GoogleContacts/>,
		route: RouteName.GContacts
	},
	{
		icon: <IconTasks style={{fontSize: 18}}/>,
		title: 'Google Tasks',
		url: '/app/gtasks',
		color: ServiceColors[Services.SERVICE_GOOGLE_TASKS],
		serviceId: Services.SERVICE_GOOGLE_TASKS,
		adminOnly: false,
		component: <GoogleTasks/>,
		route: RouteName.GTasks
	},
];

export const tools: Array<IService> = [
	{
		icon: <DashboardOutlined/>,
		title: 'Dashboard',
		url: '/app/dash',
		adminOnly: false,
		component: <Dashboard/>,
		isBaseRoute: true,
		route: RouteName.Dashboard
	},
	{
		icon: <TeamOutlined/>,
		title: 'Users',
		url: '/app/users',
		adminOnly: true,
		component: <Users/>,
		route: RouteName.Users
	},
	{
		icon: <ClockCircleOutlined/>,
		title: 'Autobackup Settings',
		url: '/app/autobackup',
		adminOnly: false,
		component: <Autobackup/>,
		route: RouteName.Autobackup
	},
	{
		icon: <CalendarOutlined/>,
		title: 'Activity',
		url: '/app/activity',
		adminOnly: false,
		component: <Activity/>,
		route: RouteName.Activity
	},
];

export const accountMenu: Array<IService> = [
	{
		icon: <SettingOutlined/>,
		title: 'Account Settings',
		url: '/app/settings',
		adminOnly: false,
		component: <AccountSettings/>,
		route: RouteName.Account
	},
	{
		icon: <CreditCardOutlined/>,
		title: 'Billing',
		url: '/app/upgrade',
		adminOnly: true,
		component: <Upgrade/>,
		route: RouteName.Billing
	}
];

const exceptions: IService[] = [
	{
		icon: <></>,
		title: '',
		url: null,
		adminOnly: false,
		component: <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist."/>,
		code: 404,
		route: RouteName.Error404
	}
];

interface IRoutes {
	list: IService[];
	prefix: string;
}

export const BuildRoutes: FC<IRoutes> = props => {
	const {list, prefix} = props;
	const context = useContext(AppContext);
	const {authUser} = context;
	const routes: ReactElement[] = [];
	list.forEach((service: IService, idx: number) => {
		if (service.url !== null) {
			let component = service.component;
			if (service.adminOnly && !authUser?.admin) {
				component = exceptions.find(srv => srv.code === 404)?.component;
			}
			routes.push(
				<Route path={service.url as string} key={prefix + idx} element={component}/>
			);
		}
	});
	routes.push(
		<Route path={"/search"} key={'search-files'} element={<SearchContainer/>}/>
	);
	routes.push(
		<Route path={"/app/photos/connect"} key={"connect" + routes.length} element={<PhotosConnectCallback/>}/>
	);
	routes.push(
		<Route path={"/app/google/connect"} key={"google_connect" + routes.length} element={<GoogleConnectCallback/>}/>
	);
	routes.push(
		<Route path="*" key="err4040" element={
			<Result
				status="404"
				title="404"
				subTitle="Sorry, the page you visited does not exist."
			/>
		}/>
	);
	return <Routes key="builded_routes">{routes}</Routes>;
};

export const getBaseRoute = (): IService => {
	return [...tools, ...services, ...accountMenu].find(srv => srv.isBaseRoute === true) || {} as IService;
};

export const getRoute = (route: RouteName): IService => {
	return [...tools, ...services, ...accountMenu].find(srv => srv.route === route) || {} as IService;
};

export const isValidPath = (path:string) => {

}