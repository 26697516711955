import React, {CSSProperties, useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {Alert, Button, Col, Form, Input, Layout, Row, Typography} from 'antd';
import {EyeInvisibleOutlined, EyeTwoTone, IconSpin, LockOutlined, UserOutlined} from "../../icons/icons";
import {deleteFromLocalStorage, doAuth, isValidInvitationCode, storeToLocalStorage} from "../../tools/Tools";
import MFACodeInput from "../../components/MFACodeInput";
import Redirector from "../../tools/Redirector";
import {GoogleLogin} from '@react-oauth/google';
import DPAForm from "../authCallback/components/DPAForm";
import {AppConfig} from "../../Config";
import styles from "../authCallback/components/DPAForm.module.scss";

const {Title} = Typography;

const baseUrl = Redirector.getUri();
const url: URL = new URL(window.location.href);
const code: string | null = url.searchParams.get('code');

const GoogleSignIn = () => {

	const [authenticated, setAuthenticated] = useState(false);
	const [form] = Form.useForm();
	const [error, setError] = useState<string | null>(null);
	const [showMfa, setShowMfa] = useState<boolean>(false);
	const [wait, setWait] = useState<boolean>(false);
	const [mfaError, setMfaError] = useState(false);
	const [showRegistration, setShowRegistration] = useState<boolean>(false);
	const [idToken, setIdToken] = useState<string | null>(null);
	const [mfaCode, setMfaCode] = useState<string | null>(null)
	const makeAuthRequest = (code: string | null) => {

		if (code) {
			setError(null);
			let data: any;
			if(idToken === null) {
				data = {
					"code": code,
					"google-signin": true
				};
			} else if(mfaCode !== null) {
				data = {
					'id_token' : idToken,
					'mfa_code' : mfaCode
				};
			}
			doAuth(data)
				.then((data: any) => {
					if (data && data.token) {
						storeToLocalStorage('token', data.token);
						setAuthenticated(true);
					}
				})
				.catch(err => {
					setError(err.message);
					switch (err.code) {
						case "A403":
							setError(err.message);
							setShowMfa(false);
							break;
						case "M403":
							setError(null);
							!showMfa && setShowMfa(true);
							setIdToken(err.additional.id_token);
							break;
						case "M404":
						case "M405":
							setError(err.message);
							setShowMfa(true);
							setMfaError(true);
							break;
						case "R404":
							setShowRegistration(true);
							setShowMfa(false);
							setMfaError(false);
							break;
						case "GL403":
							setError(err.message);
							setIdToken(null);
							break;
						default:
							setError(err.message);
							break;
					}
				});
		}
	};

	useEffect(() => {
		makeAuthRequest(code);
	}, []);

	if(authenticated) {
		return <Navigate to={baseUrl}/>;
	}

	return (
		showRegistration ?
			<DPAForm
				loading={false}
				onAgree={(v) => {
					localStorage.setItem('agree', v.join(","));
					window.location.href = AppConfig.getGoogleAuthUrl()
				}}/>
			:
			<Layout className={styles.dpaForm}>
			<Row align={"top"} justify={"center"} style={{
				minHeight: '100vh',
				height: '100vh',
				alignItems: 'stretch',
				overflow: 'auto',
			}}>
				<Col lg={12} xl={9} md={12} sm={20} xs={24} style={{
					background: "#fff"
				}}>
					<Row style={{alignItems: "center", height: "100%", justifyContent: "center"}}>
						<Col lg={16}>
							{error && !showMfa && <Alert message={error} type={"error"} style={{marginBottom: '24px'}}/>}
							{showMfa ?
								<div style={{textAlign: "center"}}>
									<div className={styles.logo}>
										<IconSpin style={{fontSize: 140}}/>
									</div>
									<MFACodeInput
										title={<h3 style={{textAlign: "center"}}>MFA Required</h3>}
										numInputs={6}
										description={"Enter 6 digit code from your app"}
										hasError={mfaError}
										errorMessage={error}
										onChange={(val) => {
											setMfaError(false);
											setError(null);
											setMfaCode(val);
										}}
									/>
									<Button type="primary" onClick={() => makeAuthRequest(code)} disabled={wait}
									        size={"large"} style={{marginTop: 15}}>
										Continue
									</Button>
								</div> : null}
						</Col>
					</Row>
				</Col>
			</Row>
		</Layout>
	);
};

export default GoogleSignIn;